/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Button, Subtitle } from '@swp/components'
import SiteHeader from '../../components/de/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Prázdna stránka"}>
        <SiteHeader />

        <Column className="pb--50 pt--60" name={"einleitung"}>
          
          <ColumnWrap className="column__flex --center el--1 pb--0 pl--0 pr--0 pt--0 flex--center" style={{"maxWidth":1280}} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5 pb--0 pt--0" anim={"5"} animS={"5"} style={{"maxWidth":900}}>
              
              <Title className="title-box fs--72" content={"Name der Seite"}>
              </Title>

              <Text className="text-box fs--18" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"inhalt-1"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Text className="text-box" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textes, die Schriftgröße und den Zeilenabstand ändern und sogar die maximale Breite des Textes in Pixeln einstellen."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1odcdv6 pb--80 pt--80" name={"kontakt"} parallax={false} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/6c089215f41d4cfbb338c78781278a98_ove=18191dx52__s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/6c089215f41d4cfbb338c78781278a98_ove=18191dx52__s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/6c089215f41d4cfbb338c78781278a98_ove=18191dx52__s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/6c089215f41d4cfbb338c78781278a98_ove=18191dx52__s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/6c089215f41d4cfbb338c78781278a98_ove=18191dx52__s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/6c089215f41d4cfbb338c78781278a98_ove=18191dx52__s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/6c089215f41d4cfbb338c78781278a98_ove=18191dx52__s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/11212/6c089215f41d4cfbb338c78781278a98_ove=18191dx52__s=3000x_.jpeg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s2" anim={"7"} animS={"2"}>
              
              <Title className="title-box fs--72 mt--16" style={{"maxWidth":966}} content={"<span style=\"color: white;\">Haben Sie fragen?</span><br>"}>
              </Title>

              <Button className="btn-box btn-box--hvr2 btn-box--shape5 fs--22" content={"Kontaktieren Sie uns"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"partei"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 el--3" anim={"2"} animS={"2"} style={{"maxWidth":1420}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Auto Body Shop Johnson</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":366}} content={"<span style=\"color: white;\">Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Über uns</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":366}} content={"<span style=\"color: white;\">Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Kontakt</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":473}} content={"<span style=\"color: white;\">+49 797 811 2X05<br>Oeder Weg 22</span>"}>
              </Text>

              <Text className="text-box" content={"<span style=\"color: white;\">Firmen-Ident.-Nr.:: 12345678<br>Erstellt mit <a href=\"https://saywebpage.com\">saywebpage.com</a></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}